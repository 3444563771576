<template>
  <div class="content-box-padding">
    <div class="line-text" style="margin-top: 20px">
      国务院《“十三五”生态环境保护规划》国发【2016】65号明确指出：<br />
      1、实施大气环境质量目标管理和期限达标规划。<br />
      2、各省（区、市）和地级及以上城市及时修编重污染天气应急预案，开展重污染天气成因分析和污染物来源解析，科学制定针对性减排措施，每年更新应急减排措施项目清单。<br />
      南京浦蓝大气根据实践经验，提出“监测基础+分析评估+驻场服务+专家会商”的环保管家服务模式，协助城市完成空气质量持续改善年度目标。<br />
      构建点-线-面监测体系，获取充分监测数据；<br />
      专业研判分析，可阐明污染过程，量化来源贡献，提升管控措施的科学性；<br />
      丰富的环境空气质量管控服务经验，措施落地性强。<br />
      以环境空气质量整体改善为目标，协同管控PM2.5和O3污染；<br />
    </div>
    <img
      src="~assets\image\business\大气污染防治第三方绩效服务.png"
      alt="大气污染防治第三方绩效服务"
      style="margin: 50px auto 0px auto; width: 80%"
    />
  </div>
</template>
